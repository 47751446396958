@import "src/styles/variables";

.main_container {
  margin-inline: auto;
  width: calc(100% - 1rem);
  position: relative;
}

.item_list {
  height: fit-content;
  display: flex;
  gap: 1px;
  margin-left: 0.5rem;
}

.blur {
  filter: blur(4px);
  user-select: none;
}

.item {
  transition: all 0.5s;
  height: auto;
  width: fit-content;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  border: $input-border;
  font-family: "Lato", sans-serif;
  bottom: -5px;
  position: relative;
  &.active {
    background-color: $secondary-background;
    bottom: -1px;
  }
  &:hover {
    background-color: $secondary-background;
    bottom: -1px;
  }
}

.content {
  width: 100%;
  position: relative;
  padding: 1rem;
  border: $main-border;
  background-color: $secondary-background;
  border-radius: $border-radius;
}

.chartContainer {
  width: 100%;
}
.optionContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .uniteSelector {
    display: flex;
    gap: 1rem;
    border: $input-border;
    padding: 0.25rem;
    border-radius: 10px;
    background-color: #00000011;
    width: 250px;
  }

  .doubleUniteSelector {
    display: flex;
    gap: 0.5rem;
    width: 100%;

    .doubleUniteSelectorItem {
      background-color: #00000011;
      border: $input-border;
      padding: 0.25rem;
      border-radius: 10px;
      width: 200px;
    }
  }
}

.list {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.dataTable {
  border-collapse: separate;
  border-spacing: 0;
  border: $header-border;
  border-radius: $border-radius;
  width: 100%;
  tbody,
  tr {
    td:first-child {
      text-align: left;
      width: min-content;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px;
    }

    border-radius: $border-radius;
  }
  td,
  th {
    border-left: $header-border;
    border-top: $header-border;
    text-align: center;
    padding: 0.1rem 0.25rem;
    font-family: "Lato", sans-serif;
    font-size: 0.75rem;
    color: $main-black;
    & input {
      background: transparent;
    }
  }
  th {
    position: relative;
    min-width: 140px;
    border-top: none;
    font-weight: 700;
    .subTitle {
      font-size: 0.65rem;
      opacity: 0.7;
    }
  }
  td:first-child,
  th:first-child {
    border-left: none;
  }
  tr:last-child {
    td:first-child {
      border-bottom-left-radius: $border-radius;
    }
    td:last-child {
      border-bottom-right-radius: $border-radius;
    }
  }
  tr.mean {
    td,
    th {
      border-top: $main-stroke double;
    }
  }
}

.notAccesIrr {
  thead tr:first-child th:nth-child(n + 2):nth-child(-n + 3) {
    border-bottom: 1px solid #e0e0e0;
  }

  tbody {
    tr td:first-child {
      border-right: 1px solid #e0e0e0;
    }

    br + tr ~ tr td:first-child {
      background-color: white;
    }

    br + tr {
      th {
        border-right: 1px solid #e0e0e0;
      }

      td:nth-child(-n + 3) {
        border-bottom: 1px solid #e0e0e0;
      }

      td:nth-child(-n + 2) {
        border-left: none;
      }
    }
  }
}

.notAccesWater {
  thead tr:nth-child(even) th:nth-child(-n + 2) {
    border-bottom: 1px solid #e0e0e0;
  }

  tbody br + tr td:nth-child(n + 4):nth-child(-n + 5) {
    border-bottom: 1px solid #e0e0e0;
  }
}

.notAccesThermal {
  thead tr:nth-child(even) th:nth-child(n + 3) {
    border-bottom: 1px solid #e0e0e0;
  }

  tbody br + tr td:nth-child(n + 6) {
    border-bottom: 1px solid #e0e0e0;
  }
}

.downloadContainer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  .chartToPngButton {
    padding: 2px;
    width: fit-content;
    height: fit-content;
    border-radius: 25%;
    background-color: transparent;
    color: black;
    cursor: pointer;
    transition: 10ms;
    &:active {
      transform: translateY(1px);
    }
    svg {
      width: 30px;
      height: 30px;
    }
  }
}

.help-icon {
  svg {
    height: 16px;
    width: 16px;
  }
  transform: translateY(-6px);
}

.flex {
  display: flex;
  gap: 1px;
}

.justifyCenter {
  justify-content: center;
}

.limiter {
  width: 100%;
  border: $header-border;
  margin-block: 1rem;
}

.verticalLimiter {
  // height: 100%;
  border: $header-border;
  margin-block: 1rem;
}

.customTooltip {
  background-color: $main-background;
  border-radius: $border-radius;
  border: $header-border;
  padding: 0.5rem;
  filter: opacity(0.8);
}

.wrapper {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  grid-gap: 10px;
  padding: 10px;
}

.essenceContainer {
  padding: 1rem;
  background: $secondary-background;
  border-radius: $border-radius;
  border: $card-border;
  width: 90%;
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.essenceContent {
  margin-top: 0;
  max-height: 0;
  transition: all 0.15s ease-in-out;
  overflow: hidden;
  transform-origin: top;
  transform: scaleY(0);
}

.essenceContentShow {
  transition: max-height 0.15s ease-in-out;
  margin-top: 1rem;
  max-height: 9999px;
  transform: scaleY(1);
}

.essenceRow {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.toggle {
  border: none;
  background: none;
  transition: 0.15s linear;
  margin-right: 0.25rem;
}

.toggleOpen {
  transform: rotate(90deg);
}

.essenceLabel {
  font-family: "Lato", sans-serif;
  color: $main-black;
  font-size: 0.875rem;
  align-items: center;
  display: flex;

  span {
    margin-left: 0.5rem;
  }
}

.essenceName {
  font-family: "Lato", sans-serif;
  color: $main-black;
  font-size: 0.875rem;
}

.icon {
  svg {
    width: 25px;
    height: 25px;
  }
}

.configContainer {
  width: 100%;
  display: flex;

  .inputContainer {
    width: 30%;

    padding: 0.25rem 0.5rem;

    display: flex;
    flex-direction: column;
    gap: 2px;

    .inputItem {
      transition: all 0.5s;
      width: 100%;
      padding-inline: 0.25rem;
      font-family: "Lato", sans-serif;
      border-radius: 10px;
      border: $header-border;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.selected {
        transform: translateX(5px);
        background-color: #eeeeee;
      }

      &:hover {
        transform: translateX(5px);
        background-color: #eeeeee;
      }
      &:active {
        transform: translateX(5px);
        background-color: #00000000;
      }
      .icon {
        transition: all 0.5s;
        border-radius: 100%;
        padding: 1px;
        svg * {
          stroke: #bbbbbb;
        }
        &:hover {
          background-color: #dddddd;
        }
      }
    }
  }
  .displayContainer {
    margin-inline: auto;
    border-radius: 10px;
    border: $header-border;

    padding-inline: 0.5rem;
    width: 60%;
    height: 250px;
    // background-color: red;
    overflow-y: scroll;

    .title {
      width: 100%;
      position: sticky;
      top: 0;
      display: flex;
      gap: 0.5rem;
      align-items: center;
      font-family: "Lato", sans-serif;
      font-size: large;
      background-color: $secondary-background;
    }
  }
}

.chartTitle {
  width: max-content;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  font-family: "Lato", sans-serif;
  font-size: 1.5rem;
  color: $main-black;
  margin-bottom: 15px;
}

.file__icon {
  svg {
    width: 15px;
    height: 15px;
  }
}

.dataTable .specialCaseTable th:first-child {
  border-left: 1px solid #e3e3e3;
}

.dataTable .specialCaseTable {
  th {
    min-width: 70px;
  }

  .specialCaseTd {
    border-left: 1px solid #e3e3e3;
    text-align: center;
  }
  .specialCaseTd:first-child {
    border-radius: 0px;
  }
}

.dataTable .specialCaseTh {
  border-top: 1px solid #e3e3e3;
}

.dataTable .specialLastTr td {
  border-bottom: 1px solid #e3e3e3;
}

.dataTable tbody tr .specialCaseTd {
  text-align: center;
}

.etpPerPeriodContainer {
  margin-top: 20px;
}

.errorToGet {
  height: calc(50vh);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 6vh;
  color: #80808033;

  p {
    cursor: pointer;
  }
}
.subTitle {
  margin: auto;
  font-size: 20px;
  opacity: 0.7;
}

.water_table {
  th {
    min-width: unset;
  }
}
