@import "src/styles/variables";

.unlockBtn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  &:hover {
    p,
    svg {
      color: #56a179;
    }
    background-color: white;
  }
  display: flex;
  align-items: flex-end;
  gap: 6px;
  text-align: center;
  background-color: #56a179;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  border: 1px solid #56a179;
  p {
    color: white;
    font-family: "Poppins", sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
  }
  svg {
    color: white;
    height: 16px;
  }
}
