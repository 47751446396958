@import "src/styles/variables";

.headerTitle {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
  color: $main-black;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.containerSub {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.subscriptionDetails {
  display: flex;
  flex-direction: column;
  .title {
    font-weight: 600;
    font-size: 0.875rem;
    color: $main-black;
    display: inline-block;
    text-decoration: none;
    user-select: none;
  }

  .subscriptionInfos {
    padding-top: 20px;

    .notSubscribe {
      display: flex;
      justify-content: center;
      color: rgb(184, 184, 184);
      font-size: 35px;
    }
  }
}

.subscriptionPlans {
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 3vw;

  // .borderPlan {
  //     background-color: brown;
  // }

  .plan {
    position: relative;
    padding: 25px 20px;
    width: 310px;
    // background-color: #ebecec;
    .timeFeature {
      margin-bottom: 30px;
      font-size: 12px;
    }

    .projectFeature {
      display: flex;
      align-items: center;
      gap: 10px;
      svg {
        color: #56a179;
        height: 20px;
        width: 20px;
      }
    }

    h3 {
      font-size: 26px;
    }

    .element {
      display: flex;
      gap: 10px;
      text-decoration: none;
      margin-top: 5px;
      svg {
        height: 20px;
        width: 20px;
        color: #56a179;
      }
    }

    .isInactive {
      text-decoration: line-through;
      color: rgb(117, 117, 117);
    }

    .submitPlan {
      margin-top: 3vh;
      display: flex;
      justify-content: center;
    }
  }
  .plan::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 25px;
    padding: 2px;
    mask: linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0);
    mask-composite: exclude;
  }

  .subOne::before {
    background: linear-gradient(to right, #cd7f32, #d8b48f);
  }

  .subTwo::before {
    background: linear-gradient(to right, rgb(161, 161, 161), silver);
  }
  .subThree::before {
    background: linear-gradient(to right, gold, rgb(255, 244, 183));
  }
}

.titlePlan {
  font-weight: 600;
  font-size: 0.875rem;
  color: $main-black;
  display: inline-block;
  text-decoration: none;
  user-select: none;
  margin-bottom: 20px;
}

.TogglesContainer {
  width: 100%;
  display: flex;
  gap: 24px;
  flex-direction: column;
}

.headerToggle,
.titleSub {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h5 {
    font-size: 16px;
  }
}

.allToggles {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(4, 1fr);

  label::first-letter {
    text-transform: capitalize;
  }
}

.projectsInfosContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .projectsInfos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      color: #bbbbbb;
    }

    .progressProject {
      display: flex;
      align-items: center;
      gap: 6px;
      margin-bottom: 12px;
      button {
        &:hover {
          background: linear-gradient(#0d6efd, #0c5ae9);
        }
        &:active {
          background: linear-gradient(#0c5ae9, #1149bc);
        }
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background: linear-gradient(#2a91ff, #0d6efd);
        border-radius: 6px;
        color: white;
      }
    }

    .expiration {
      color: #000;
    }
  }
}
