@import "src/styles/variables";

.flex {
  display: flex;
  gap: 1rem;
}

.section-title {
  font-weight: 700;
  font-size: 16px;
  color: $main-black;
  margin-bottom: 16px;
}

.informationContainer {
  padding: 5px;
  width: 100%;
  border-radius: $border-radius;
  background-color: #efefef;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 16px;

  .informationsInput {
    width: 25%;
    padding: 12px 0px;
  }
}

.defaultName {
  width: 100%;
  padding: 0.75rem 1rem;
  outline: none;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 1.125rem;
  font-family: "Lato", sans-serif;
  color: #333837;
  font-weight: 700;
}

.error {
  border: $border-red;
}
