@import "src/styles/variables";

.modalContainer {
  display: flex;
  flex-direction: column;
  padding: 24px 24px 20px;
  gap: 20px;
  font-family: "Poppins";

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Poppins";

    p {
      font-family: inherit;
    }
    svg {
      height: 16px;
      cursor: pointer;
    }
  }

  .featuresList {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    list-style: none;
    font-family: "Poppins";
    font-size: 12px;
    padding: 0px;

    .hasFeature {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;

      svg {
        height: 12px;
        color: green;
      }

      p {
        color: #0d2119;
        font-family: inherit;
      }
    }

    .hasNotFeature {
      svg {
        color: transparent;
      }

      p {
        color: #cecece;
        text-decoration: line-through;
      }
    }
  }

  .btnChoicesContainer {
    position: relative;
    width: fit-content;
    display: flex;
    gap: 10px;
    background-color: $main-accent;
    border-radius: 20px;
    padding: 5px 5px;

    label {
      z-index: 1;
      padding: 2px 8px;
      font-size: 12px;
      color: white;
      cursor: pointer;
    }

    input {
      display: none;
    }

    .optionSelected {
      z-index: 0;
      top: 2px;
      left: 2px;
      width: 75px;
      height: 28px;
      border-radius: 15px;
      position: absolute;
      background-color: $main-accent-darkened;
      transition: left 0.25s ease-in-out;
    }

    .medium:checked ~ .optionSelected {
      left: 80px;
    }

    .premium:checked ~ .optionSelected {
      left: 159px;
    }
  }

  .pricing {
    .priceInfos {
      font-family: inherit;
      font-weight: bold;
      font-size: 24px;
      color: #0d2119;
    }
    .subTitle {
      font-family: inherit;
      font-size: 12px;
      color: rgb(187, 187, 187);
    }
  }

  .horizontalLimiter {
    height: 1px;
    width: 100%;
    background-color: rgb(223, 223, 223);
  }

  footer {
    font-family: inherit;
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 10px;

    p {
      width: 126px;
    }
    .subBtn {
      &:hover {
        background: linear-gradient(90deg, #3d8c65 0%, #2b704e 100%);
      }
      &:active {
        background: linear-gradient(90deg, #2b704e 0%, #235941 100%);
      }
      position: relative;
      z-index: 1;
      background: rgb(61, 140, 101);
      background: linear-gradient(90deg, $main-accent 0%, #3d8c65 100%);
      border: none;
      border-radius: 8px;
      padding: 0.75rem 1rem;
      color: white;

      p {
        font-family: "Poppins", sans-serif;
        font-size: 0.875rem;
        font-weight: 500;
      }
    }
    .alreadySub {
      cursor: default;
      &:hover {
        background: #8ec7a6;
      }
      &:active {
        background: #8ec7a6;
      }
      background: #8ec7a6;
    }
  }
}
