@import "src/styles/variables";

.container {
  position: relative;
  border:$card-border;
  border-radius: $border-radius;
  padding: 1.25rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: $main-background;
  height: fit-content;
  cursor: pointer;
}

.name {
  font-size: 0.875rem;
  color: $main-black;
  font-weight: 600;
  margin-bottom: 0.25rem;
  text-decoration: none;
  display: block;
}

.date {
  font-size: 0.75rem;
  color: $main-gray;
}

.expiration_date {
  position: absolute;
  top: 0px;
  right: 5px;
  font-size: 10px;
}

.green {
  color: green;
}

.orange {
  color: orange;
}

.red {
  color: red;
}